import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import About from './views/About.vue'
import Services from './views/Services.vue'
import Projects from './views/Projects.vue'
import Contact from './views/Contact.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      // redirect: "/home",
      name: 'home',
      component: Home
    },

    {
      path: '/about',
      name: 'about',
      component: About
    },

    {
      path: '/projects',
      name: 'projects',
      component: Projects
    },

    {
      path: '/services',
      name: 'services',
      component: Services
    },

    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },

    {
      path: '/',
      name: 'home',
      component: Home
    }



  ]
})
